<template>
  <sm-master-layout background-color="#f5f5f5">
    <div id="container">
      <br>
      <span class="sm-delivery__headline">
          Bitte Versandart auswählen
      </span>

      <h6 class="sm-shipping__h6">VERSANDART:</h6>

      <ion-list class="sm-shipping__list" lines="full" mode="ios">
        <ion-item-group v-for="(shippingMethod, index) in shippingMethods" :key="index">
          <ion-radio-group :value="getShippingMethod ? getShippingMethod.id : shippingMethods[0].id" mode="md">
            <ion-item @click="onToggleShippingMethod(shippingMethod)">
              <ion-row class="sm-shipping__item">
                <ion-label class="sm-shipping__item__default"
                           v-if="getSalesChannel && getSalesChannel.shippingMethodId === shippingMethod.id">
                  STANDARD-VERSANDART
                </ion-label>
                <ion-label class="sm-shipping__item__company">{{ shippingMethod.name }}</ion-label>
              </ion-row>

              <ion-radio class="sm-shipping__item__icon" slot="start" color="danger"
                         :value="shippingMethod.id"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item-group>
      </ion-list>

      <div class="sm-list-button">
        <div class="sm-button-next">
          <ion-button expand="block" color="primary" @click="() => router.push({name: 'summary'})">WEITER</ion-button>
        </div>

        <div class="sm-button-back">
          <ion-button expand="block" color="secondary" @click="() => router.back()">ZURÜCK</ion-button>
        </div>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import {
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonButton
} from '@ionic/vue';
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import ApiService from '@/services/api.service';
import { CUSTOMER_SHIPPING_API_PATH } from "@/constants/api.constant";
import { mapGetters, mapMutations } from "vuex";
import { useRouter } from 'vue-router';
import { alertCircle } from "ionicons/icons";

export default defineComponent({
  name: 'Shipping',

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    SmMasterLayout,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonButton
  },

  data() {
    return {
      shippingMethods: []
    }
  },

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  watch: {
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Kasse / Versandart');
    this.loadShippingMethodsFromApi();
    this.setNumberStep('1');
  },

  computed: {
    ...mapGetters('shipping', ['getShippingMethod']),
    ...mapGetters('auth', ['getSalesChannel']),
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle', 'setNumberStep']),
    ...mapMutations('shipping', ['setShippingMethod']),
    ...mapMutations('auth', ['getSalesChannel']),

    async loadShippingMethodsFromApi() {
      const loading = await this.presentScreenLoading();

      try {
        const response = await ApiService.post(CUSTOMER_SHIPPING_API_PATH);

        console.log(response.data);
        this.shippingMethods = response.data.elements;
        this.setShippingMethod(this.getShippingMethod || response.data.elements[0]);
        this.dismissScreenLoading(loading);
      } catch (e) {
        console.log(e);
        this.dismissScreenLoading(loading);
      }
    },

    onToggleShippingMethod(shippingMethod) {
      this.setShippingMethod(shippingMethod);
    },
  }
})
</script>

<style scoped>
  #container {
    margin-bottom: 10rem;
  }

  .sm-shipping__h6 {
    font-weight: 700;
  }

  .sm-shipping__list {
    --ion-background-color: var(--ion-color-light);
  }

  .sm-shipping__item {
    display: block;
    width: 100%;
    padding: 1rem 0;
    line-height: 20px;
  }

  .sm-shipping__item__default {
    color: darkgrey;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-size: 18px;
  }

  .sm-shipping__item__company {
    font-weight: 700;
    font-size: 14px;
  }

  .sm-shipping__item__icon {
    margin: 16px 24px 16px 16px;
  }
</style>
